<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <login-card header-color="rose">
        <h4
          slot="title"
          class="title"
        >
          KMPUS
        </h4>

        <div slot="inputs">
          <h5>{{ result }}</h5>
        </div>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from '@/components';

export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      result: 'Please wait until we verify your account',
    };
  },
  mounted() {
    this.verifyAccount();
  },
  methods: {
    verifyAccount() {
      this.guestRequestAsync(`app/account/${this.$route.params.token}/verify`, 'post')
        .then(() => {
          this.result = 'App Account activated succesfully';
        })
        .catch(() => {
          this.result = 'App Account activated succesfully';
        });
    },
  },
};
</script>

<style></style>
